<template>
  <div class="mx-auto grid max-w-screen-xl rounded-3xl bg-brick-100 px-4 py-10 md:px-10">
    <div class="flex flex-col gap-4">
      <div>
        <SharedTwoColorTitle class="text-3xl font-medium leading-none md:text-5xl" :data="data.title" />
        <SharedCkEditorBlock class="mt-4 md:text-lg" :content="data.text" />
      </div>
      <div class="grid gap-4 md:grid-cols-3">
        <BlockHealthProfessionalCard
          v-for="healthProfessional in healthProfessionals?.data"
          :key="healthProfessional.id"
          :health-professional="healthProfessional"
          class="flex flex-col items-stretch" />
      </div>
      <AppButton
        class="mt-4 self-center"
        :to="{
          name: 'fachrichtung-vorsorgemedizin-serviceGroup',
          params: { serviceGroup: navigationParam },
        }">
        Termin vereinbaren
      </AppButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import slugify from "@sindresorhus/slugify";
import { stringify } from "qs";
import type { HealthProfessional } from "~/types/strapi/api/health-professional/content-types/health-professional/health-professional";
import type { Payload } from "~/types/strapi/common/schemas-to-ts/Payload";
import type { DoctorProfiles } from "~/types/strapi/components/block/interfaces/DoctorProfiles";

const props = defineProps<{
  data: DoctorProfiles;
}>();

const healthProfessionalQuery = stringify(
  {
    fields: ["fullName", "workingTitle", "docfinderRating"],
    filters: {
      services: {
        group: {
          uuid: {
            $eqi: props.data.service_group?.data.attributes.uuid,
          },
        },
      },
    },
    populate: {
      avatar: {
        populate: "true",
      },
      languages: {
        populate: ["icon"],
      },
    },
    sort: ["lastName"],
  },
  { encodeValuesOnly: true },
);

const { data: healthProfessionals } = await useApiFetch<Payload<HealthProfessional[]>>(
  `/api/health-professionals?${healthProfessionalQuery}`,
);
const navigationParam = computed(() =>
  props.data.service_group
    ? `${slugify(props.data.service_group.data.attributes.name)}-${props.data.service_group.data.attributes.uuid}`
    : "",
);
</script>

<style scoped></style>
