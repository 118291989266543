<template>
  <div class="rounded-3xl bg-white p-6">
    <div class="flex flex-1 flex-col justify-between gap-y-4">
      <SharedPortraitImage
        v-if="portraitImageData"
        :data="portraitImageData"
        class="ob h-44 w-full object-[0px_-70px]" />
      <div class="flex flex-col gap-y-1">
        <h4 class="font-medium leading-normal">
          {{ healthProfessional.attributes.fullName }}
        </h4>
        <p class="text-sm leading-normal">{{ healthProfessional.attributes.workingTitle }}</p>
        <SharedDocfinderRating
          v-if="healthProfessional.attributes.docfinderRating"
          :rating="healthProfessional.attributes.docfinderRating" />
      </div>
      <SharedLanguageList
        v-if="healthProfessional.attributes.languages"
        :languages="healthProfessional.attributes.languages.data"
        :show-language-initials="true" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HealthProfessional } from "~/types/strapi/api/health-professional/content-types/health-professional/health-professional";

const props = defineProps<{
  healthProfessional: HealthProfessional;
}>();

const portraitImageData = computed(() => {
  return props.healthProfessional.attributes.avatar?.data.attributes;
});
</script>

<style scoped></style>
